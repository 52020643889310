import React from "react";

export default function Offers({ offers }) {
  return (
    <section aria-label="section" style={{ backgroundSize: "cover" }}>
      <div class="container" style={{ backgroundSize: "cover" }}>
        <div class="row g-custom-x" style={{ backgroundSize: "cover" }}>
          <div class="col-lg-12">
            <div class="text-center">
              <div class="title-box-outer wow flipInX">
                <div class="title-box-inner wow flipInX" data-wow-delay=".2s">
                  <h2 class="wow fadeInUp" data-wow-delay=".2s">
                    <span class="id-color">01</span> Offers
                  </h2>
                </div>
              </div>
            </div>
          </div>
          {offers.map((offer) => (
            <div
              class="col-lg-4 col-md-6 mb20"
              style={{ backgroundSize: "cover" }}
            >
              <div class="de-event-item" style={{ backgroundSize: "cover" }}>
                <div class="d-content" style={{ backgroundSize: "cover" }}>
                  <div class="d-image" style={{ backgroundSize: "cover" }}>
                    <span class="d-image-wrap">
                      <img alt="" src={offer.image} class="lazy" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
