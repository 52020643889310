import React from "react";

export default function About({ timings, url }) {
  return (
    <section>
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <div class="title-box-outer wow flipInX">
              <div class="title-box-inner wow flipInX" data-wow-delay=".2s">
                <h2 class="wow fadeInUp" data-wow-delay=".2s">
                  <span class="id-color">03</span> About Us
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div class="row">
          <div class="col-md-8 col-sm-12 text-center bg-light">
            <iframe
              src={url}
              width="100%"
              height="350"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="col-md-4 col-sm-12">
            <div
              style={{
                borderColor: "white",
                borderWidth: 0.3,
                borderStyle: "solid",
                height: "100%",
              }}
              className="p-1"
            >
              <div
                style={{
                  borderColor: "white",
                  borderWidth: 0.3,
                  borderStyle: "solid",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
                className="mb-3 p-3"
              >
                <h3 className="text-center">WORK TIMINGS</h3>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="timing-rows"
                    style={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {timings.map((t, index) => (
                      <div className="row timing-row" key={index}>
                        <div className="col-6 col-sm-6 col-md-6 text-left">
                          {t.day}
                        </div>
                        <div className="col-6 col-sm-6 col-md-6 text-right">
                          {t.timings}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
