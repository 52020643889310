import React from "react";

export default function AnimatedCall({ phone }) {
  return (
    <>
      <a href={`tel:${phone}`}>
        <div className="pulse">
          <img
            src="images/phone.png"
            alt="images description"
            width="25"
            className="img-fluid"
          />
        </div>
      </a>
    </>
  );
}
