import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Gallery from "../../components/gallery/Gallery";
import MakeReservation from "../../components/MakeReservation";
import About from "../../components/About";
import Offers from "../../components/Offers";
import BelowBanner from "../../components/BelowBanner";
import Banner from "../../components/Banner";

export default function Home() {
  const timings = [
    { day: "Sunday", timings: "Closed" },
    { day: "Monday", timings: "Closed" },
    { day: "Tuesday", timings: "Closed" },
    { day: "Wednesday", timings: "9 PM–5 AM" },
    { day: "Thursday", timings: "9 PM–5 AM" },
    { day: "Friday", timings: "9 PM–5 AM" },
    { day: "Saturday", timings: "9 PM–5 AM" },
  ];
  const offers = [
    { image: "images/promo/veracruz/1.jpg" },
    { image: "images/promo/veracruz/2.jpg" },
    { image: "images/promo/veracruz/3.jpg" },
    { image: "images/promo/veracruz/4.jpg" },
    { image: "images/promo/veracruz/5.jpg" },
    { image: "images/promo/veracruz/6.jpg" },
  ];
  const banners = [
    {
      image: "images/banners/veracruz/1.png",
      title: "Carnaval de Veracruz",
      subtitle: "Let's Enjoy Together",
    },
    {
      image: "images/banners/veracruz/2.png",
      title: " Foods,Drinks & Music",
      subtitle: "At Affordable Prices",
    },
    {
      image: "images/banners/veracruz/3.png",
      title: "Games,Rewards & Events",
      subtitle: "And Lot's of FUN",
    },
  ];
  const videoRef = React.useRef(null);

  React.useEffect(() => {
    const video = videoRef.current;

    const handlePlay = () => {
      video.play().catch((error) => {
        console.error("Error attempting to play video:", error);
      });
    };

    const handlePause = () => {
      video.play().catch((error) => {
        console.error("Error attempting to resume video:", error);
      });
    };

    // Add event listeners
    video.addEventListener("pause", handlePause);
    document.body.addEventListener("click", handlePlay);

    // Cleanup event listeners on component unmount
    return () => {
      video.removeEventListener("pause", handlePause);
      document.body.removeEventListener("click", handlePlay);
    };
  }, []);

  return (
    <div id="wrapper">
      <Header></Header>

      <div className="no-bottom no-top" id="content">
        <div id="top"></div>
        {/* //carousel */}
        <Banner banners={banners}></Banner>
        {/* //Location */}
        <BelowBanner></BelowBanner>
        {/* //promotions */}
        <Offers offers={offers}></Offers>

        {/* //Foods */}
        <div className="container mt-4">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="title-box-outer wow flipInX">
                <div
                  className="title-box-inner wow flipInX"
                  data-wow-delay=".2s"
                >
                  <h2 className="wow fadeInUp" data-wow-delay=".2s">
                    <span className="id-color">02</span> FOODS
                  </h2>
                </div>
              </div>
            </div>
            <div className="spacer-single"></div>
          </div>
        </div>
        <section className="cover-section">
          <video ref={videoRef} className="cover-video" muted loop>
            <source src="images/food.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="cover-content">
            <img
              style={{ width: 300, height: 100 }}
              alt=""
              src="images/logo.png"
            />
            <p style={{ fontSize: 30, marginTop: 10 }}>
              Enjoy Huge Variety of Food & Drinks !!!!!
            </p>
          </div>
        </section>
        {/* //gallery */}
        <Gallery></Gallery>
        {/* //Follow Us On */}
        <About
          url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.61552820442!2d-96.1169448!3d19.1682997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85c341d8272bc2ad%3A0xf96c9c2d07f66e77!2sJala%20la%20Jarra%2C%20Boca%20del%20Rio%2C%20Veracruz.!5e0!3m2!1sen!2smx!4v1730391147485!5m2!1sen!2smx"
          timings={timings}
        ></About>
      </div>
      <a href="#" id="back-to-top"></a>
      <Footer></Footer>
    </div>
  );
}
