import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Gallery from "../../components/gallery/Gallery";
import MakeReservation from "../../components/MakeReservation";
import Banner from "../../components/Banner";
import BelowBanner from "../../components/BelowBanner";
import Offers from "../../components/Offers";
import About from "../../components/About";

export default function Home() {
  const timings = [
    { day: "Sunday", timings: "8 PM–5 AM" },
    { day: "Monday", timings: "Closed" },
    { day: "Tuesday", timings: "Closed" },
    { day: "Wednesday", timings: "Closed" },
    { day: "Thursday", timings: "8 PM–5 AM" },
    { day: "Friday", timings: "8 PM–5 AM" },
    { day: "Saturday", timings: "8 PM–5 AM" },
  ];
  const banners = [
    {
      image: "images/banners/manzanillo/2.png",
      title: "Manzanillo Get Ready",
      subtitle: "Let's Enjoy Together",
    },
    {
      image: "images/banners/manzanillo/3.png",
      title: "Feel The Beat",
      subtitle: "Fell the energy",
    },
    {
      image: "images/banners/manzanillo/1.png",
      title: "Feel The Beat",
      subtitle: "Let's Enjoy Together",
    },
  ];
  const offers = [
    { image: "images/promo/manzanillo/1.jpg" },
    { image: "images/promo/manzanillo/2.jpg" },
    { image: "images/promo/manzanillo/3.jpg" },
    { image: "images/promo/manzanillo/4.jpg" },
  ];
  return (
    <div id="wrapper">
      <Header></Header>

      <div class="no-bottom no-top" id="content">
        <div id="top"></div>
        <Banner banners={banners}></Banner>
        <BelowBanner></BelowBanner>
        <Offers offers={offers}></Offers>

        {/* //gallery */}
        <Gallery></Gallery>
        {/* //Follow Us On */}
        <About
          timings={timings}
          url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.3590147586797!2d-104.31600949999999!3d19.091899800000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8424d5a431e6905b%3A0xec62fa7c0401001b!2sJala%20La%20Jarra%20-%20Restaurante%2C%20Bar%20%26%20Beach%20Club%20-!5e0!3m2!1sen!2smx!4v1730391192238!5m2!1sen!2smx"
        ></About>
      </div>
      <a href="#" id="back-to-top"></a>
      <Footer></Footer>
    </div>
  );
}
