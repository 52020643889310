import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Gallery from "../../components/gallery/Gallery";
import MakeReservation from "../../components/MakeReservation";
import Banner from "../../components/Banner";
import About from "../../components/About";
import BelowBanner from "../../components/BelowBanner";
import Offers from "../../components/Offers";

export default function Home() {
  const timings = [
    { day: "Sunday", timings: "Closed" },
    { day: "Monday", timings: "Closed" },
    { day: "Tuesday", timings: "Closed" },
    { day: "Wednesday", timings: "7 PM–2:30 AM" },
    { day: "Thursday", timings: "7 PM–2:30 AM" },
    { day: "Friday", timings: "7 PM–2:30 AM" },
    { day: "Saturday", timings: "7 PM–2:30 AM" },
  ];
  const banners = [
    {
      image: "images/banners/cholula/1.jpg",
      title: "Cholula",
      subtitle: "Let's Enjoy Together",
    },
    {
      image: "images/banners/cholula/2.jpg",
      title: "Cholula",
      subtitle: "Let's Enjoy Together",
    },
    {
      image: "images/banners/cholula/3.jpg",
      title: "Feel The Beat",
      subtitle: "Let's Enjoy Together",
    },
  ];
  const offers = [
    { image: "images/promo/cholula/1.jpg" },
    { image: "images/promo/cholula/2.jpg" },
    { image: "images/promo/cholula/3.jpg" },
    { image: "images/promo/cholula/4.jpg" },
    { image: "images/promo/cholula/5.jpg" },
    { image: "images/promo/cholula/6.jpg" },
    { image: "images/promo/cholula/7.jpg" },
    { image: "images/promo/cholula/8.jpg" },
    { image: "images/promo/cholula/9.jpg" },
  ];
  return (
    <div id="wrapper">
      <Header></Header>

      <div class="no-bottom no-top" id="content">
        <div id="top"></div>
        <Banner banners={banners}></Banner>
        <BelowBanner></BelowBanner>
        {/* offers */}
        <Offers offers={offers}></Offers>

        {/* //gallery */}
        <Gallery></Gallery>
        {/* //Follow Us On */}
        <About
          url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.1863674089486!2d-98.29278470000001!3d19.055541899999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cfc7598ac7716b%3A0x9e5480c9f5b8588f!2sJALA%20LA%20JARRA!5e0!3m2!1sen!2smx!4v1730391071947!5m2!1sen!2smx"
          timings={timings}
        ></About>
      </div>
      <a href="#" id="back-to-top"></a>
      <Footer></Footer>
    </div>
  );
}
