import React from "react";
import { useLocation } from "react-router-dom";

export default function Header() {
  const location = useLocation();
  const { hash, pathname, search } = location;

  return (
    <header class="transparent">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="de-flex sm-pt10">
              <div class="de-flex-col">
                <div class="de-flex-col">
                  <div id="logo">
                    <a href="/">
                      <img
                        style={{ width: 200, height: 70 }}
                        alt=""
                        src={`images/logo.png`}
                      />
                    </a>
                  </div>
                </div>
                <div class="de-flex-col"></div>
              </div>
              <div class="de-flex-col header-col-mid">
                <ul id="mainmenu">
                  <li>
                    <a href={`/${pathname.split("/")[1]}/home`}>Home</a>
                  </li>
                  <li>
                    <a href={`/${pathname.split("/")[1]}/fooddrinks`}>Menu</a>
                  </li>
                  <li>
                    <a href={`/${pathname.split("/")[1]}/reservation`}>
                      Reservations
                    </a>
                  </li>
                  <li>
                    <a href={`/${pathname.split("/")[1]}/contact`}>Reach Us</a>
                  </li>
                  <li>
                    <a href={`/${pathname.split("/")[1]}/faq`}>Faqs</a>
                  </li>
                </ul>
              </div>
              <div class="de-flex-col">
                <div class="menu_side_area">
                  <a href="#section-tickets" class="btn-main">
                    <i class="fa fa-sign-in"></i>
                    <span>Book Table</span>
                  </a>
                  <span id="menu-btn"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
