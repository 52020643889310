import React from "react";
import { useNavigate } from "react-router-dom";
import "./Main.css"; // Import your CSS file where you define styles

export default function Main() {
  const navigate = useNavigate();

  return (
    <div className="container-fluid p-0" style={{ overflow: "hidden" }}>
      <div className="row no-gutters">
        <div
          onClick={() => {
            navigate("/cholula/home");
          }}
          className="col-md-4 col-12 bg-cover"
          style={{ backgroundImage: "url('images/main1.jpg')" }}
        >
          <div className="overlay" style={{ background: "#000000db" }}></div>
          <img src="images/main1.jpg" alt="Main 1" className="image" />
          <div className="logo">
            <img className="logoimg" src="images/logo.png" alt="Main 1" />
            <h3 className="branchname">
              <a href="/cholula/home" style={{ color: "#fff" }}>
                Cholula, Puebla
              </a>
            </h3>
          </div>
        </div>
        <div
          onClick={() => {
            navigate("/manzanillo/home");
          }}
          className="col-md-4 col-12 bg-cover"
          style={{ backgroundImage: "url('images/main2.jpg')" }}
        >
          <div className="overlay" style={{ background: "#000000db" }}></div>
          <img src="images/main2.jpg" alt="Main 1" className="image" />
          <div className="logo">
            <img className="logoimg" src="images/logo.png" alt="Main 1" />
            <h3 className="branchname">
              <a href="/manzanillo/home" style={{ color: "#fff" }}>
                Manzanillo
              </a>
            </h3>
          </div>
        </div>
        <div
          onClick={() => {
            navigate("/veracruz/home");
          }}
          className="col-md-4 col-12 bg-cover"
          style={{ backgroundImage: "url('images/main3.jpg')" }}
        >
          <div className="overlay" style={{ background: "#000000db" }}></div>
          <img src="images/main3.jpg" alt="Main 1" className="image" />
          <div className="logo">
            <img className="logoimg" src="images/logo.png" alt="Main 1" />
            <h3 className="branchname">
              <a href="/veracruz/home" style={{ color: "#fff" }}>
                Veracruz
              </a>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}
