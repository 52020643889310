import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

export default function Table() {
  return (
    <div id="wrapper">
      <Header></Header>
      <div class="no-bottom no-top" id="content">
        <section
          id="subheader"
          class="text-light"
          data-bgimage="url(images/table.jpg) center"
        >
          <div class="center-y relative text-center">
            <div class="container">
              <div class="row">
                <div class="col-md-12 text-center">
                  <h1>TABLE RESERVATIONS</h1>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </section>

        <section aria-label="section">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <form
                  name="contactForm"
                  id="contact_form"
                  class="form-border"
                  method="post"
                >
                  <div class="row g-custom-x justify-content-center">
                    <div class="col-md-12 mb-5">
                      <h3 className="text-center">
                        To submit a request for table reservations, please fill
                        the form below
                      </h3>
                    </div>
                    <div class="col-md-8">
                      <div className="row">
                        <div className="col-md-6">
                          <label>Date</label>
                          <input
                            type="date"
                            name="email"
                            id="email"
                            class="form-control"
                            required
                          />
                        </div>
                        <div className="col-md-6">
                          <label>Party Name</label>
                          <input
                            type="text"
                            name="email"
                            id="email"
                            class="form-control"
                            required
                          />
                        </div>
                      </div>
                      <div>
                        <label>Email</label>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          class="form-control"
                          required
                        />
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label>Country</label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            class="form-control"
                            required
                          />
                        </div>
                        <div className="col-md-6">
                          <label>Phone</label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            class="form-control"
                            required
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label>Party Size</label>
                          <input
                            type="text"
                            name="email"
                            id="email"
                            class="form-control"
                            required
                          />
                        </div>
                        <div className="col-md-6">
                          <label>Budget</label>
                          <input
                            type="text"
                            name="email"
                            id="email"
                            class="form-control"
                            required
                          />
                        </div>
                      </div>
                      <label>Comments</label>
                      <textarea
                        name="comments"
                        id="email"
                        class="form-control"
                        required
                      />
                    </div>

                    <div class="col-md-12 justify-content-center">
                      <p id="submit" class="mt20 text-center">
                        <input
                          type="submit"
                          id="send_message"
                          value="Submit Form"
                          class="btn btn-main"
                        />
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>

      <a href="#" id="back-to-top"></a>
      <Footer></Footer>
    </div>
  );
}
