import React, { useEffect, useState } from "react";

import PhotoAlbum from "react-photo-album";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import veracruzPhotos from "./veracruzPhotos";
import cholulaPhotos from "./cholulaPhotos";
import manzanilloPhotos from "./manzanilloPhotos";
import { useLocation } from "react-router-dom";

export default function Gallery() {
  const [index, setIndex] = useState(-1);
  const [photos, setPhotos] = useState(veracruzPhotos);
  const location = useLocation();
  const { hash, pathname, search } = location;

  useEffect(() => {
    if (pathname.split("/")[1] == "cholula") {
      setPhotos(cholulaPhotos);
    } else if (pathname.split("/")[1] == "veracruz") {
      setPhotos(veracruzPhotos);
    } else {
      setPhotos(manzanilloPhotos);
    }
  }, []);
  return (
    <>
      <section>
        <div class="container">
          <div class="row">
            <div class="col-md-12 text-center">
              <div class="title-box-outer wow flipInX">
                <div class="title-box-inner wow flipInX" data-wow-delay=".2s">
                  <h2 class="wow fadeInUp" data-wow-delay=".2s">
                    <span class="id-color">02</span> Gallery
                  </h2>
                </div>
              </div>
            </div>
            <div class="spacer-single"></div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <PhotoAlbum
                photos={photos}
                layout="columns"
                // targetRowHeight={150}
                onClick={({ index }) => setIndex(index)}
              />

              <Lightbox
                slides={photos}
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                // enable optional lightbox plugins
                plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
