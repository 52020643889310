import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function Faq() {
  const faqArray = [
    {
      title: "TABLE BOOKINGS",
      desp: "For table reservations, please visit our reservations page",
      id: "1",
    },
    {
      title: "LOST ITEMS",
      desp: "For Lost Items Please contact us at",
      id: "2",
    },
    {
      title: "HOURS OF OPERATION",
      desp: ` Venue open from Thursday – Sunday.
        <br></br>
        Hours of operation: 11:00pm-5am. Available seven days a{" "}
        <br></br>
        week for private bookings. See our ‘calendar’ for{" "}
        <br></br>
        upcoming events.`,
      id: "3",
    },
    {
      title: "REFUNDS",
      desp: "All tickets are final sale and cannot be exchanged or refunded. In the case of an event cancellation without a rescheduled date, a full refund will be automatically issued to each patron on the credit card used to purchase. By purchasing a ticket to this event, you agree to this purchase policy. Before purchasing your tickets, we urge you to confirm the title, time and location of the event.",
      id: "4",
    },
    {
      title: "PRIVATE EVENTS",
      desp: "Please visit our corporate page for more information or contact our events team at: events@groothq.com",
      id: "5",
    },
    { title: "AGE REQUIREMENTS", desp: "Any age can visit", id: "6" },
  ];
  return (
    <div id="wrapper">
      <Header></Header>
      <div class="no-bottom no-top" id="content">
        <section
          id="subheader"
          class="text-light"
          data-bgimage="url(images/faq.jpg) center"
        >
          <div class="center-y relative text-center">
            <div class="container">
              <div class="row">
                <div class="col-md-12 text-center">
                  <h1>Faq</h1>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </section>

        <section aria-label="section">
          <div class="container">
            <div class="row" style={{ justifyContent: "center" }}>
              <div class="col-md-8 col-sm-12">
                <div class="accordion" id="accordionExample">
                  {faqArray.map((fq) => (
                    <div class="accordion-item">
                      <h2 class="accordion-header" id={`heading${fq.id}`}>
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse${fq.id}`}
                          aria-controls={`collapse${fq.id}`}
                        >
                          {fq.title}
                        </button>
                      </h2>
                      <div
                        id={`collapse${fq.id}`}
                        class="accordion-collapse collapse"
                        aria-labelledby={`heading${fq.id}`}
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">{fq.desp}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <a href="#" id="back-to-top"></a>
      <Footer></Footer>
    </div>
  );
}
