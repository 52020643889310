import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AnimatedCall from "./AnimatedCall";

export default function Footer() {
  const location = useLocation();
  const { hash, pathname, search } = location;
  const [address, setAddress] = useState({
    address: "14 oriente 420B, C. Fresnos 19, 72825 San Andrés Cholula, Pue.",
    phone: "+523122252200",
    email: "",
    url: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.1863674089486!2d-98.29278470000001!3d19.055541899999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cfc7598ac7716b%3A0x9e5480c9f5b8588f!2sJALA%20LA%20JARRA!5e0!3m2!1sen!2smx!4v1718810150299!5m2!1sen!2smx",
    facebook: "",
    instagaram: "",
  });
  useEffect(() => {
    if (pathname.split("/")[1] == "cholula") {
      setAddress({
        facebook: "https://www.facebook.com/share/1Jiz3t7jKq/?mibextid=LQQJ4d",
        instagaram:
          "https://www.instagram.com/jalalajarra.pue?igsh=b2tjdWd1dHo4emIw",
        address:
          "14 oriente 420B, C. Fresnos 19, 72825 San Andrés Cholula, Pue.",
        phone: "+523122252200",
        email: "",
        url: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.1863674089486!2d-98.29278470000001!3d19.055541899999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cfc7598ac7716b%3A0x9e5480c9f5b8588f!2sJALA%20LA%20JARRA!5e0!3m2!1sen!2smx!4v1718810150299!5m2!1sen!2smx",
      });
    } else if (pathname.split("/")[1] == "veracruz") {
      setAddress({
        facebook: "https://www.facebook.com/share/1Atoks4Drq/?mibextid=LQQJ4d",
        instagaram:
          "https://www.instagram.com/jalalajarra.veracruz?igsh=bHFsaWlhMzE3b3Rh",
        address:
          "Bv. Adolfo Ruíz Cortines 9, Costa Verde, 94294 Veracruz, Ver.",
        phone: "+522223278908",
        email: "",
        url: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.61552820442!2d-96.1169448!3d19.1682997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85c341d8272bc2ad%3A0xf96c9c2d07f66e77!2sJala%20la%20Jarra%2C%20Boca%20del%20Rio%2C%20Veracruz.!5e0!3m2!1sen!2smx!4v1718810094820!5m2!1sen!2smx",
      });
    } else {
      setAddress({
        facebook: "https://www.facebook.com/share/1G7YpdLyDr/?mibextid=LQQJ4d",
        instagaram:
          "https://www.instagram.com/jalalajarra_mzo?igsh=ZDl3bWo0M2ZhOHl5",
        url: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.3590147586797!2d-104.31600949999999!3d19.091899800000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8424d5a431e6905b%3A0xec62fa7c0401001b!2sJala%20La%20Jarra%20-%20Restaurante%2C%20Bar%20%26%20Beach%20Club%20-!5e0!3m2!1sen!2smx!4v1718810034534!5m2!1sen!2smx",
        address: "Playa Azul Salagua, 28867 Manzanillo, Colima",
        phone: "+523141332720",
        email: "",
      });
    }
  }, []);
  return (
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-sm-6 col-xs-1">
            <div class="widget">
              <h5>Contact Info</h5>
              <address class="s1">
                <span>
                  <i class="id-color fa fa-map-marker fa-lg"></i>
                  {address.address}
                </span>
                <span>
                  <i class="id-color fa fa-phone fa-lg"></i>
                  {address.phone}
                </span>
                {/* <span>
                  <i class="id-color fa fa-envelope-o fa-lg"></i>
                  <a href="mailto:contact@example.com">{address.email}</a>
                </span>    */}
              </address>
              <div class="de-flex-col">
                <div class="social-icons">
                  <a href={`${address.facebook}`}>
                    <i class="fa fa-facebook fa-lg"></i>
                  </a>

                  <a href={`${address.instagaram}`}>
                    <i class="fa fa-instagram fa-lg"></i>
                  </a>
                  {/* <a href="#">
                    <i class="fa fa-pinterest fa-lg"></i>
                  </a>
                  <a href="#">
                    <i class="fa fa-rss fa-lg"></i>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 col-xs-1">
            <div class="widget">
              <h5>Quick Links</h5>
              <ul>
                <li>
                  <a href={`/${pathname.split("/")[1]}/fooddrinks`}>Menu</a>
                </li>
                <li>
                  <a href={`/${pathname.split("/")[1]}/reservetation`}>
                    Reservations
                  </a>
                </li>
                <li>
                  <a href={`/${pathname.split("/")[1]}/contact`}>Reach Us</a>
                </li>
                <li>
                  <a href={`/${pathname.split("/")[1]}/faq`}>Faqs</a>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-md-4 col-sm-6 col-xs-1">
            <div class="widget">
              <h5>Newsletter</h5>
              <p>
                Signup for our newsletter to get the latest news in your inbox.
              </p>
              <form
                action="blank.php"
                class="row form-dark"
                id="form_subscribe"
                method="post"
                name="form_subscribe"
              >
                <div class="col text-center">
                  <input
                    class="form-control"
                    id="txt_subscribe"
                    name="txt_subscribe"
                    placeholder="enter your email"
                    type="text"
                  />{" "}
                  <a href="#" id="btn-subscribe">
                    <i class="arrow_right bg-color-secondary"></i>
                  </a>
                  <div class="clearfix"></div>
                </div>
              </form>
              <div class="spacer-10"></div>
              <small>Your email is safe with us. We don't spam.</small>
            </div>
          </div>
        </div>
      </div>
      <div class="subfooter">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="de-flex">
                <div class="de-flex-col text-center">
                  <span class="copy">
                    &copy; Copyright {new Date().getFullYear()} - JALALAJARRA
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="stickyPhone">
        <a
          href={`https://api.whatsapp.com/send?phone=${address.phone}`}
          target="_blank"
        >
          <img
            src="images/whatsapp.svg"
            alt="images description"
            width="80"
            className="img-fluid"
          />
        </a>
      </div>
      <AnimatedCall phone={address.phone}></AnimatedCall>
    </footer>
  );
}
