import React, { useState } from "react";
import HTMLFlipBook from "react-pageflip";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const imageUrls = [
  "images/menu/veracuz-menu/1.jpg",
  "images/menu/veracuz-menu/2.jpg",
  "images/menu/veracuz-menu/3.jpg",
  "images/menu/veracuz-menu/4.jpg",
  "images/menu/veracuz-menu/5.jpg",
  "images/menu/veracuz-menu/6.jpg",
  "images/menu/veracuz-menu/7.jpg",
  "images/menu/veracuz-menu/8.jpg",
  "images/menu/veracuz-menu/9.jpg",
  "images/menu/veracuz-menu/10.jpg",
  "images/menu/veracuz-menu/11.jpg",
  "images/menu/veracuz-menu/12.jpg",
  "images/menu/veracuz-menu/13.jpg",
];

export default function Test() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? imageUrls.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === imageUrls.length - 1 ? 0 : prevIndex + 1
    );
  };
  const handleDownloadClick = () => {
    const link = document.createElement("a");
    link.href = "/menu/veracruz-menu.pdf";
    link.download = "veracruz-menu.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
      <div id="wrapper">
        <Header />
        <div className="no-bottom no-top" id="content">
          <section
            id="subheader"
            className="text-light"
            data-bgimage="url(images/food.jpg) center"
          >
            <div className="center-y relative text-center">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 text-center">
                    <h1>Food & Drinks</h1>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </section>

          <section className="mb-5">
            <div className="container ">
              <div className="row justify-content-center mt-3 mb-3">
                <div className="col-auto">
                  <button
                    onClick={handlePrevClick}
                    className="btn btn-primary me-2"
                  >
                    Previous
                  </button>
                  <button
                    onClick={handleNextClick}
                    className="me-2 btn btn-primary"
                  >
                    Next
                  </button>
                  <button
                    onClick={handleDownloadClick}
                    className="btn btn-warning btn-block mt-2"
                  >
                    Download
                  </button>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-12 col-md-6 text-center">
                  <img
                    src={imageUrls[currentIndex]}
                    alt={`Menu ${currentIndex + 1}`}
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            {/* <HTMLFlipBook
              width={360}
              height={600}
              showCover={true}
              flippingTime={1000}
              maxShadowOpacity={0.5}
              mobileScrollSupport={true}
            >
              {imageUrls.map((url, index) => (
                <div key={index} className="page">
                  <div className="image-container">
                    <img
                      src={url}
                      alt={`Page ${index + 1}`}
                      className="responsive-image"
                    />
                  </div>
                </div>
              ))}
            </HTMLFlipBook> */}
          </section>
        </div>

        <a href="#" id="back-to-top"></a>
        <Footer />
      </div>
    </>
  );
}
