import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useLocation } from "react-router-dom";

export default function ContactUs() {
  const location = useLocation();
  const { pathname } = location;
  const [address, setAddress] = useState({
    address: "14 oriente 420B, C. Fresnos 19, 72825 San Andrés Cholula, Pue.",
    phone: "+523122252200",
    email: "",
    url: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.1863674089486!2d-98.29278470000001!3d19.055541899999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cfc7598ac7716b%3A0x9e5480c9f5b8588f!2sJALA%20LA%20JARRA!5e0!3m2!1sen!2smx!4v1718810150299!5m2!1sen!2smx",
    timings: [
      { day: "Sunday, 12 PM–3 AM" },
      { day: "Monday, Closed" },
      { day: "Tuesday, Closed" },
      { day: "Wednesday, 12 PM–3 AM" },
      { day: "Thursday, 12 PM–3 AM" },
      { day: "Friday, 12 PM–3 AM" },
      { day: "Saturday, 12 PM–3 AM" },
    ],
  });
  useEffect(() => {
    if (pathname.split("/")[1] == "cholula") {
      setAddress({
        address:
          "14 oriente 420B, C. Fresnos 19, 72825 San Andrés Cholula, Pue.",
        phone: "+523122252200",
        email: "",
        url: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.1863674089486!2d-98.29278470000001!3d19.055541899999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cfc7598ac7716b%3A0x9e5480c9f5b8588f!2sJALA%20LA%20JARRA!5e0!3m2!1sen!2smx!4v1718810150299!5m2!1sen!2smx",
        timings: [
          { day: "Sunday, 12 PM–3 AM" },
          { day: "Monday, Closed" },
          { day: "Tuesday, Closed" },
          { day: "Wednesday, 12 PM–3 AM" },
          { day: "Thursday, 12 PM–3 AM" },
          { day: "Friday, 12 PM–3 AM" },
          { day: "Saturday, 12 PM–3 AM" },
        ],
      });
    } else if (pathname.split("/")[1] == "veracruz") {
      setAddress({
        address:
          "Bv. Adolfo Ruíz Cortines 9, Costa Verde, 94294 Veracruz, Ver.",
        phone: "+522223278908",
        email: "",
        url: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.61552820442!2d-96.1169448!3d19.1682997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85c341d8272bc2ad%3A0xf96c9c2d07f66e77!2sJala%20la%20Jarra%2C%20Boca%20del%20Rio%2C%20Veracruz.!5e0!3m2!1sen!2smx!4v1718810094820!5m2!1sen!2smx",
        timings: [
          { day: "Sunday, 7 PM–5 AM" },
          { day: "Monday, Closed" },
          { day: "Tuesday, Closed" },
          { day: "Wednesday, 5 PM–5 AM" },
          { day: "Thursday, 7 PM–5 AM" },
          { day: "Friday, 7 PM–5 AM" },
          { day: "Saturday, 7 PM–5 AM" },
        ],
      });
    } else {
      setAddress({
        url: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.3590147586797!2d-104.31600949999999!3d19.091899800000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8424d5a431e6905b%3A0xec62fa7c0401001b!2sJala%20La%20Jarra%20-%20Restaurante%2C%20Bar%20%26%20Beach%20Club%20-!5e0!3m2!1sen!2smx!4v1718810034534!5m2!1sen!2smx",
        address: "Playa Azul Salagua, 28867 Manzanillo, Colima",
        phone: "",
        email: "",
        timings: [
          { day: "Sunday, 8 PM–5 AM" },
          { day: "Monday, Closed" },
          { day: "Tuesday, Closed" },
          { day: "Wednesday, 8 PM–5 AM" },
          { day: "Thursday, 8 PM–5 AM" },
          { day: "Friday, 8 PM–5 AM" },
          { day: "Saturday, 8 PM–5 AM" },
        ],
      });
    }
  }, []);

  return (
    <div id="wrapper">
      <Header></Header>
      <div class="no-bottom no-top" id="content">
        <section
          id="subheader"
          class="text-light"
          data-bgimage="url(images/contact.jpg) center"
        >
          <div class="center-y relative text-center">
            <div class="container">
              <div class="row">
                <div class="col-md-12 text-center">
                  <h1>Contact Us</h1>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </section>

        <section aria-label="section">
          <div class="container">
            <div class="row">
              <div class="col-md-4">
                <form
                  name="contactForm"
                  id="contact_form"
                  class="form-border"
                  method="post"
                >
                  <div class="row g-custom-x">
                    <div class="col-md-12">
                      <h3>Send Us Message</h3>
                    </div>
                    <div class="col-md-12">
                      <div id="name_error" class="error">
                        Please enter your name.
                      </div>
                      <div>
                        <input
                          type="text"
                          name="Name"
                          id="name"
                          class="form-control"
                          placeholder="Your Name"
                          required
                        />
                      </div>

                      <div id="email_error" class="error">
                        Please enter your valid E-mail ID.
                      </div>
                      <div>
                        <input
                          type="email"
                          name="Email"
                          id="email"
                          class="form-control"
                          placeholder="Your Email"
                          required
                        />
                      </div>

                      <div id="phone_error" class="error">
                        Please enter your phone number.
                      </div>
                      <div>
                        <input
                          type="text"
                          name="phone"
                          id="phone"
                          class="form-control"
                          placeholder="Your Phone"
                          required
                        />
                      </div>
                      <div id="message_error" class="error">
                        Please enter your message.
                      </div>
                      <div>
                        <textarea
                          name="message"
                          id="message"
                          class="form-control"
                          placeholder="Your Message"
                          required
                        ></textarea>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <p id="submit" class="mt20">
                        <input
                          type="submit"
                          id="send_message"
                          value="Submit Form"
                          class="btn btn-main"
                        />
                      </p>
                      <div id="mail_success" class="success">
                        Your message has been sent successfully.
                      </div>
                      <div id="mail_fail" class="error">
                        Sorry, error occured this time sending your message.
                      </div>
                    </div>
                  </div>
                </form>

                <div id="success_message" class="success">
                  Your message has been sent successfully. Refresh this page if
                  you want to send more messages.
                </div>
                <div id="error_message" class="error">
                  Sorry there was an error sending your form.
                </div>
              </div>
              <div class="col-md-4">
                <div class="widget widget_text text-center">
                  <h3>Timing</h3>
                  <address>
                    {address.timings.map((t) => (
                      <span>{t.day}</span>
                    ))}
                  </address>
                </div>
              </div>
              <div id="sidebar" class="col-md-4">
                <div class="widget widget_text ">
                  <h3>Contact Info</h3>
                  <address>
                    <span>
                      <strong>Address:</strong>
                      {address.address}
                    </span>
                    <span>
                      <strong>Phone:</strong> {address.phone}
                    </span>
                    <span>
                      <strong>Email:</strong>
                      <a href="mailto:contact@example.com">{address.email}</a>
                    </span>
                  </address>
                </div>
              </div>
            </div>
          </div>
        </section>
        <iframe
          src={address.url}
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

      <a href="#" id="back-to-top"></a>
      <Footer></Footer>
    </div>
  );
}
