import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

export default function Banner({ banners }) {
  return (
    <Carousel
      autoPlay={true}
      interval={2000}
      infiniteLoop={true}
      showThumbs={false}
    >
      {banners.map((b, index) => (
        <div key={index} className="banner">
          <img src={b.image} alt={b.title} />
          <div className="mask">
            <div className="d-flex justify-content-center align-items-center h-100">
              <div className="container text-white text-center">
                <div className="row">
                  <div className="col-md-8 offset-md-2">
                    <div className="box-line-outer wow flipInX">
                      <div className="box-line-inner">
                        <h1 className="ultra-big mb-3 wow fadeInUp">
                          {b.title}
                        </h1>
                        {/* <div className="col-md-6 offset-md-3">
                          <h4
                            className="s2 lead wow fadeInUp"
                            data-wow-delay=".3s"
                          >
                            {b.subtitle}
                          </h4>
                        </div> */}
                      </div>
                    </div>
                    <div className="btn-main-wrap">
                      <a
                        href="#section-artists"
                        className="btn-main wow fadeInUp"
                        data-wow-delay=".6s"
                      >
                        Make a Reservation
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  );
}
