const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];

function assetLink(asset, width) {
  return `/images/${asset}?w=${width}&q=75`;
}
const photos = [
  { asset: "gallery/manzanillo/1.jpg", width: 1080, height: 1080 },
  { asset: "gallery/manzanillo/2.jpg", width: 1080, height: 1620 },
  { asset: "gallery/manzanillo/3.jpg", width: 1080, height: 720 },
  { asset: "gallery/manzanillo/4.jpg", width: 1080, height: 1620 },
  { asset: "gallery/manzanillo/5.jpg", width: 1080, height: 1080 },
  { asset: "gallery/manzanillo/6.jpg", width: 1080, height: 720 },
  { asset: "gallery/manzanillo/7.jpg", width: 1080, height: 720 },
  { asset: "gallery/manzanillo/8.jpg", width: 1080, height: 1620 },
  // { asset: "gallery/veracruz/9.jpg", width: 1080, height: 720 },
  // { asset: "gallery/veracruz/10.jpg", width: 1080, height: 1620 },
  // { asset: "gallery/veracruz/11.jpg", width: 1080, height: 1620 },
].map(({ asset, width, height }) => ({
  src: assetLink(asset, width),
  width,
  height,
  srcSet: breakpoints.map((breakpoint) => ({
    src: assetLink(asset, breakpoint),
    width: breakpoint,
    height: Math.round((height / width) * breakpoint),
  })),
}));

export default photos;
