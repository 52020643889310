import React from "react";

export default function BelowBanner() {
  return (
    <>
      <section id="section-date" class="no-top no-bottom">
        <div class="container-fluid">
          <div class="row align-items-center bg-color">
            <div class="col-lg-6 pt40 pb20">
              <div class="text-center">
                <h3 class="s2 wow fadeInUp" data-wow-delay="0s">
                  Jala la Jarra, Boca del Rio, Veracruz.{" "}
                </h3>
                <a
                  target="_blank"
                  type="button"
                  href="https://maps.app.goo.gl/7AHsysKCfxmvSLab9"
                  className="btn btn-default btn-dark"
                >
                  Locate Now
                </a>
              </div>
            </div>
            <div class="col-lg-6 pt40 pb20" data-bgcolor="#0b168d">
              <div class="text-center">
                <h2 class="s2 wow fadeInUp" data-wow-delay="0s">
                  Jun 26 - Jul 02
                </h2>
                <h3 class="wow fadeInUp" data-wow-delay=".2s">
                  Veracruz, Mexico
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
