import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/veracruz/Home";
import React from "react";
import Table from "./pages/veracruz/Table";
import Menu from "./pages/veracruz/Menu";
import Main from "./pages/Main";
import Faq from "./pages/Faq";
import ContactUs from "./pages/ContactUs";

import CHome from "./pages/cholula/Home";
import CMenu from "./pages/cholula/Menu";

import MHome from "./pages/manzanillo/Home";
import MMenu from "./pages/manzanillo/Menu";

const App = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/veracruz/home" element={<Home />} />
          <Route path="/veracruz/reservation" element={<Table />} />
          <Route path="/veracruz/fooddrinks" element={<Menu />} />
          <Route path="/veracruz/faq" element={<Faq />} />
          <Route path="/veracruz/contact" element={<ContactUs />} />

          <Route path="/cholula/home" element={<CHome />} />
          <Route path="/cholula/reservation" element={<Table />} />
          <Route path="/cholula/fooddrinks" element={<CMenu />} />
          <Route path="/cholula/faq" element={<Faq />} />
          <Route path="/cholula/contact" element={<ContactUs />} />

          <Route path="/manzanillo/home" element={<MHome />} />
          <Route path="/manzanillo/reservation" element={<Table />} />
          <Route path="/manzanillo/fooddrinks" element={<MMenu />} />
          <Route path="/manzanillo/faq" element={<Faq />} />
          <Route path="/manzanillo/contact" element={<ContactUs />} />
        </Routes>
      </Router>
    </>
  );
};
export default App;
